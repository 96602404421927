import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Button from "../components/button"
import { formatReadingTime } from "../utils/helpers"

class Blog extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
        <Bio />
        <div style={{ margin: "20px 0 40px" }}>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <StyledLink key={node.fields.slug} to={`/blog${node.fields.slug}`}>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                    marginTop: rhythm(0 / 4),
                  }}
                >
                  {title}
                </h3>
                <small style={{ color: `rgb(177, 177, 181)`, }}>{node.frontmatter.date}{` • ${formatReadingTime(node.timeToRead)}`}</small>
                <p
                  style={{
                    margin: rhythm(0 / 4),
                  }}
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </StyledLink>
            )
          })}
        </div>
        <div style={{ textAlign: `center`,}}>
        <Link style={{ boxShadow: `none`,}} to="/">
          <Button marginTop="85px">home</Button>
        </Link>
        </div>
      </Layout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
  box-shadow: rgba(0,0,0,0.25) 0px 20px 40px;
  will-change: transform;
  color: unset;
  padding: 2.5rem;
  background: rgba(55, 56, 60, 1);
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(50,50,46);
  border-image: initial;
  -webkit-transition: box-shadow 0.2s ease 0s;
  transition: box-shadow 0.2s ease 0s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px, rgba(0, 0, 0, 0.25) 0px 2px 2px;
  }
`